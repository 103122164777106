import { axiosInstance, type CustomResponseFormat } from "api/instance";

export const ACQUISITION_DOMAIN = "acquisition/channel";

export const ACQUISITION_SUMBIT_ENDPOINT = "submit";

export const acquisitionApi = {
  submit: (channelName: string, etcDetail: string) =>
    axiosInstance.post<void>(
      `${ACQUISITION_DOMAIN}/${ACQUISITION_SUMBIT_ENDPOINT}`,
      { channelName: channelName, etcDetail: etcDetail }
    ),
};
