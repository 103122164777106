import { atom } from "recoil";

export interface UserType {
  accessToken?: string;
  role?: string;
  profileImageUrl?: string;
  nickname?: string;
  userId?: number;
  assignedCoachId?: string;
  coachAssignDueDate?: string;
  isParticipationInfoRequired?: boolean;
  promotion?: { type: string; countDownStartedAt: string };
  freeTrial?: { endDateTime: string };
  hasPraised?: boolean;
  subscription?: {
    hasStarted: boolean;
    hasFreeTrialUsed?: boolean;
    hasFirstMonthBenefitReceived: boolean;
    isSubscribing: boolean;
    expiryDaysLeft?: number;
  };
  isSubscriptionManagementEnabled?: boolean;
  isCoachAiSupportDisabled?: boolean;
  isInAutoProcessingStage?: boolean;
  isAiCoachProduct?: boolean;
  isCoachChanged?: boolean;
  assignedCoachName?: string;
  isBehavioralActivationProgram?: boolean;
  isAcquisitionChannelSubmitted?: boolean;
}

export const userAtom = atom<UserType | undefined>({
  key: "user",
  default: undefined,
  effects: [
    ({ setSelf, onSet }) => {
      const savedData = localStorage.getItem("user");
      // setSelf: atom 값을 설정 혹은 재설정
      if (savedData) setSelf(JSON.parse(savedData));

      // atom이 변화가 감지될 때 작동, Storage에 데이터 저장
      // setSelf에 의해서는 작동하지 않음
      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem("user")
          : localStorage.setItem("user", JSON.stringify(newValue));
      });
    },
  ],
});
