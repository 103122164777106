import { Box, Button, Stack, Textarea } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import { acquisitionApi } from "api/acquisitionApi";
import useDetectIOS from "hooks/useDetectIOS";
import { useState } from "react";
import FullPageTemplate from "./FullPageTemplate";

export default function Acquisition({
  title,
  progress,
  actionAfterSubmit,
}: {
  title: string;
  progress: number;
  actionAfterSubmit: () => void;
}) {
  const { isIOS } = useDetectIOS();
  const channelList = [
    "브런치",
    "네이버 카페",
    "네이버 블로그",
    "디스턴싱 오픈카톡방",
    "디스턴싱 홈페이지",
    "인스타그램",
    "쓰레드",
    `${isIOS ? "앱 스토어" : "플레이 스토어"}에서 둘러보다`,
    "누군가의 추천",
    "기타",
  ];

  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [etcDetail, setEtcDetail] = useState<string>("");

  const { mutate: submit } = useMutation(
    () => acquisitionApi.submit(selectedChannel || "", etcDetail),
    {
      onSuccess: () => {
        actionAfterSubmit();
      },
    }
  );

  return (
    <FullPageTemplate
      title={title}
      buttonText={"다음"}
      buttonDisabled={
        !selectedChannel ||
        (selectedChannel === "기타" && etcDetail.length === 0)
      }
      progress={progress}
      content={
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            flexWrap: "wrap",
            gap: 1,
            pb: "40px",
          }}
        >
          {channelList.map((option, optionIndex) => (
            <Box key={`channel_option`} sx={{ flexGrow: 1 }}>
              <Button
                fullWidth
                size="md"
                variant={"outlined"}
                color={selectedChannel === option ? "primary" : "secondary"}
                value={option}
                onClick={(e) => {
                  setSelectedChannel(option);
                }}
              >
                {option}
              </Button>
            </Box>
          ))}
          {selectedChannel === "기타" && (
            <Textarea
              value={etcDetail}
              onChange={(e) => {
                setEtcDetail(e.target.value);
              }}
              placeholder="어떻게 알고 오셨는지 알려주세요"
              sx={{
                px: "10px",
                py: "12px",
                minHeight: "50px",
                width: "100%",
                borderWidth: "1px",
                borderColor: "divider",
                boxShadow: "none",
                backgroundColor: "white",
                "--joy-focus-thickness": "0px",
                "--variant-borderWidth": "0px",
                "--Textarea-gap": "0px",
              }}
            ></Textarea>
          )}
        </Stack>
      }
      buttonClickAction={submit}
    ></FullPageTemplate>
  );
}
