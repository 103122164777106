import { Box, Stack } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import { useMediaQuery } from "@mui/material";
import {
  DoubleLeftArrowSvg,
  LogoWithTextSvg,
} from "static/image/common/SvgAssets";
import ModeToggle from "components/header/ModeToggle";
import PatientList from "components/patient-list/PatientList";
import { isDev } from "config";
import useIsInappWebview from "hooks/useIsInappWebview";
import useSidebarToggle from "hooks/useSidebarToggle";
import useSubscriptionStatus from "hooks/useSubscriptionStatus";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { layoutAtom } from "recoil/layoutAtom";
import { patientAtom } from "recoil/patientAtom";
import { userAtom } from "recoil/userAtom";
import { addAlpha, extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";
import UserDropDown from "../user/UserDropDown";
import CxButton from "./CXButton";
import PaymentModule from "./PaymentModule2";
import HomeTaskList from "components/task-list/HomeTaskList";

export type SidebarMenuType = "patient" | "task";

export default function Sidebar() {
  const [{ isLeftSideBarOpen }, setLayoutState] = useRecoilState(layoutAtom);
  const user = useRecoilValue(userAtom);
  const isCoach = user?.accessToken && user?.role !== "patient";
  const patientState = useRecoilValue(patientAtom);
  const isInappWebview = useIsInappWebview();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const { isCanceled, isExpired, isNotStarted } = useSubscriptionStatus();

  const [currentMenu, setCurrentMenu] = useState<SidebarMenuType>(
    !isCoach ? "task" : "patient"
  );

  const { closeSidebar } = useSidebarToggle({ direction: "left" });

  return (
    <Sheet>
      <Sheet
        className="Sidebar"
        sx={{
          position: {
            xs: "fixed",
            lg: !isLeftSideBarOpen ? "fixed" : "sticky",
          },
          transform: !isLeftSideBarOpen ? "translateX(-100%)" : "translateX(0)",
          transition: "transform 0.4s, width 0.4s",
          width: "var(--Sidebar-width)",
          // height: {
          //   xs: "calc(100% - var(--StickyHeader-height, 0))",
          //   lg: !isLeftSideBarOpen
          //     ? "calc(100% - var(--StickyHeader-height, 0))"
          //     : "100%",
          // },
          height: "100%",

          zIndex: { xs: 1000, lg: 0 },
          top: 0,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          // borderRight: "1px solid",
          // borderColor: "background.level3",
          backgroundColor: "background.level2",
          overflow: "hidden",
        }}
      >
        <Stack sx={{ px: "20px" }}>
          <Stack
            direction={"row"}
            justifyContent={isSmall ? "center" : "space-between"}
            alignItems={"center"}
            sx={{
              height: "var(--StickyHeader-height)",
              minHeight: "var(--StickyHeader-height)",
              ...(!isSmall && {
                borderBottom: "1px solid",
                borderColor: "divider",
              }),
            }}
            {...(!isSmall && { onClick: closeSidebar })}
          >
            <LogoWithTextSvg />
            {!isSmall && patientState?.patientId && <DoubleLeftArrowSvg />}
          </Stack>
        </Stack>

        <Stack
          direction={"row"}
          sx={{
            width: "200%",
            minHeight: 0,
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "var(--Sidebar-width)",
              px: "20px",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
            }}
          >
            {isCoach && <PatientList setCurrentMenu={setCurrentMenu} />}
          </Box>

          <Box
            sx={{
              width: "var(--Sidebar-width)",
              pb: 10,
              minHeight: 0,
              height: "100%",
              overflow: "hidden auto",
              transform:
                currentMenu === "task" ? "translateX(-100%)" : "translateX(0)",
              transition: "transform 0.4s, width 0.4s",
              backgroundColor: "background.level2",
              zIndex: 10,
            }}
          >
            <HomeTaskList setCurrentMenu={setCurrentMenu} />
          </Box>
        </Stack>
        {user?.role === "patient" &&
          (isCanceled || isExpired || isDev ? (
            <Box>
              <Box
                sx={{
                  px: "20px",
                  pb: "20px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-12px",
                    zIndex: 10,
                    left: 0,
                    width: "100%",
                    height: "12px",
                    background: `linear-gradient(180deg, ${addAlpha(
                      extractHexColor(theme.vars.palette.background.level2),
                      0
                    )} 0%, ${addAlpha(
                      extractHexColor(theme.vars.palette.background.level2),
                      1
                    )} 100%)`,
                  }}
                />
                <PaymentModule type="card" />
              </Box>
            </Box>
          ) : (
            <></>
          ))}

        <Box
          sx={{
            borderTop: "1px solid",
            borderColor: "divider",
            mx: "20px",
            py: "20px",
          }}
        >
          <Box>
            <Stack direction={"row"} justifyContent="space-between">
              {user && user.accessToken && <UserDropDown user={user} />}
              <Stack direction={"row"} spacing={1}>
                {/* {isCoach && <CoachHelpButton />} */}
                {/* <StoreReviewButton /> */}
                {!isCoach && <CxButton />}
                {/* {!isInappWebview && <ModeToggle />} */}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Sheet>
    </Sheet>
  );
}
