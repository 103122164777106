import React, { useState } from "react";
import { Box, Button, Sheet, Stack } from "@mui/joy";
import TitleWithSubtitle from "components/common/TitleWithSubtitle";
import { permanentDataAtom } from "recoil/permanentDataAtom";
import { useRecoilValue } from "recoil";
import ProgressBar from "components/common/ProgressBar";
import { extractHexColor } from "styles/hexOpacity";
import { theme } from "styles/theme";

export const institutionData = [
  { name: "서울대학교", domain: "snu.ac.kr" },
  { name: "카이스트", domain: "kaist.ac.kr" },
  { name: "오웰헬스", domain: "orwellhealth.org" },
];

export default function FullPageTemplate({
  title,
  image,
  content,
  buttonText,
  buttonDisabled,
  buttonClickAction,
  progress = 0,
}: {
  title: string;
  image?: string;
  content?: React.ReactNode;
  buttonText: string;
  buttonDisabled?: boolean;
  buttonClickAction: () => void;
  progress?: number;
}) {
  const permanantDataState = useRecoilValue(permanentDataAtom);

  return (
    <Sheet
      sx={{
        width: "100vw",
        height: "calc(var(--vh,1vh) * 100)",
        minHeight: "calc(var(--vh,1vh) * 100)",
        backgroundColor: "white",
      }}
    >
      <Stack
        direction={"column"}
        alignItems="center"
        justifyContent={"center"}
        sx={{
          maxWidth: "400px",
          mx: "auto",
          height: "100%",
        }}
      >
        <Stack sx={{ width: "100%", pt: "20px", pb: "20px", px: "20px" }}>
          <ProgressBar progress={progress} />
        </Stack>
        <Stack sx={{ px: "20px", position: "relative", zIndex: 100 }}>
          <TitleWithSubtitle title={title} />
          <Box
            sx={{
              position: "absolute",
              bottom: -20,
              width: "100%",
              height: "20px",
              background: `linear-gradient(0deg, #ffffff00 0%, #ffffff 100%)`,
            }}
          />
        </Stack>
        <Stack
          sx={{
            pt: "20px",
            width: "100%",
            flex: 1,
            overflow: "auto",
            px: "20px",
            position: "relative",
          }}
        >
          {image ? (
            <Stack
              direction="column"
              justifyContent={"center"}
              sx={{ flex: 1, pb: "20px" }}
            >
              <img
                src={image}
                alt={"onboarding"}
                // style={{ objectFit: "contain" }}
              />
            </Stack>
          ) : (
            content
          )}
        </Stack>
        <Stack
          sx={{
            px: "20px",
            width: "100%",
            pb: `${permanantDataState?.homeIndicatorHeight || 20}px`,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -20,
              width: "100%",
              height: "20px",
              background: `linear-gradient(180deg, #ffffff00 0%, #ffffff 100%)`,
            }}
          />
          <Button
            size="lg"
            sx={{ width: "100%" }}
            disabled={buttonDisabled}
            onClick={buttonClickAction}
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
    </Sheet>
  );
}
