import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import { useEffect, useRef } from "react";
import { TaskType } from "api";
import { Stack } from "@mui/joy";
import { useRecoilState, useRecoilValue } from "recoil";
import { menuSelector } from "recoil/menuAtom";
import TaskItem from "./TaskItem";
import { UpArrowSvg } from "static/image/common/SvgAssets";
import { MeditationItemType, ThoughtRecordItemType } from "api/homeApi";
import ToolItem from "./ToolItem";
import { patientAtom } from "recoil/patientAtom";
import { freeTaskList, onboardingTaskId } from "./LearningActivityList";
import { getTaskIdFromTaskKey } from "logic/logics";
import { automaticThoughtIdType } from "api/conceptApi";

export type menuKeyType =
  | "taskList_open"
  | "taskList_close"
  | "thoughtRecordList_open"
  | "thoughtRecordList_close"
  | "meditationList_open"
  | "meditationList_close";

export default function NestedList({
  menuKey,
  title,
  taskList,
  thoughtRecordList,
  meditationList,
  initiallyOpen,
}: {
  menuKey: menuKeyType;
  title: string;
  taskList?: TaskType[];
  thoughtRecordList?: ThoughtRecordItemType[];
  meditationList?: MeditationItemType[];
  initiallyOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useRecoilState(menuSelector(menuKey));
  const patientState = useRecoilValue(patientAtom);
  const patientId = patientState?.patientId || "";

  useEffect(() => {
    if (initiallyOpen) {
      setIsOpen(true);
    }
  }, [initiallyOpen]);

  function getAutomaticThoughtId(taskId: string) {
    const automaticThoughtId = taskId
      .split("_")[1]
      ?.split("#")[0] as automaticThoughtIdType;
    return automaticThoughtId || 0;
  }

  // taskList
  //   ?.sort((a, b) => {
  //     const setNumberA = a.setNumber ?? 0;
  //     const setNumberB = b.setNumber ?? 0;

  //     return setNumberA - setNumberB;
  //   })
  //   ?.sort((a, b) => {
  //     const automaticThoughtIdA = getAutomaticThoughtId(a.taskId);
  //     const automaticThoughtIdB = getAutomaticThoughtId(b.taskId);
  //     if (
  //       (automaticThoughtIdA || automaticThoughtIdB) &&
  //       !a.taskId.startsWith("7-") &&
  //       !b.taskId.startsWith("7-")
  //     ) {
  //       if (automaticThoughtIdA < automaticThoughtIdB) return -1;
  //       if (automaticThoughtIdA > automaticThoughtIdB) return 1;
  //       return 0;
  //     }

  //     return 0;
  //   })
  //   ?.sort((a, b) => {
  //     const automaticThoughtIdA = getAutomaticThoughtId(a.taskId);
  //     const automaticThoughtIdB = getAutomaticThoughtId(b.taskId);
  //     if (
  //       automaticThoughtIdB &&
  //       a.taskId.startsWith("7-") &&
  //       b.taskId.startsWith("7-")
  //     ) {
  //       if (automaticThoughtIdA < automaticThoughtIdB) return -1;
  //       if (automaticThoughtIdA > automaticThoughtIdB) return 1;
  //       return 0;
  //     }
  //     return 0;
  //   });

  taskList
    ?.sort((a, b) => {
      const setNumberA = a.setNumber ?? 0;
      const setNumberB = b.setNumber ?? 0;

      return setNumberA - setNumberB;
    })
    ?.sort((a, b) => {
      const automaticThoughtIdA = getAutomaticThoughtId(a.taskId);
      const automaticThoughtIdB = getAutomaticThoughtId(b.taskId);
      if (!a.taskId.startsWith("7-") && !b.taskId.startsWith("7-")) {
        if (automaticThoughtIdA < automaticThoughtIdB) return -1;
        if (automaticThoughtIdA > automaticThoughtIdB) return 1;
        return 0;
      }

      return 0;
    })
    ?.sort((a, b) => {
      const automaticThoughtIdA = getAutomaticThoughtId(a.taskId);
      const automaticThoughtIdB = getAutomaticThoughtId(b.taskId);
      if (a.taskId.startsWith("7-") && b.taskId.startsWith("7-")) {
        if (automaticThoughtIdA < automaticThoughtIdB) return -1;
        if (automaticThoughtIdA > automaticThoughtIdB) return 1;
        return 0;
      }
      return 0;
    });

  if (taskList) {
    const getThoughtTrapNum = (taskId: string) => {
      return taskId.split("-")[2];
    };
    for (let i = taskList.length - 1; i >= 0; i--) {
      const currentTask = taskList[i];
      const match44 = currentTask.taskId.match(/^4-4-(\d+)-A/);

      if (match44) {
        for (let j = i + 1; j < taskList.length; j++) {
          const nextTask = taskList[j];
          const match45 = nextTask.taskId.match(/^4-5-(\d+)-A/);
          console.log("[TEST]", match44, match45);
          if (
            match45 &&
            getThoughtTrapNum(currentTask.taskId) ===
              getThoughtTrapNum(nextTask.taskId)
          ) {
            // "4-4-{n}-A"를 "4-5-{n}-A" 바로 앞에 놓기
            const task44 = taskList.splice(i, 1)[0]; // "4-4-{n}-A" 제거
            taskList.splice(j - 1, 0, task44); // "4-5-{n}-A" 앞에 삽입
            break;
          }
        }
      }
    }
  }

  const taskRefs = useRef<HTMLDivElement[]>([]);

  return (
    <Box>
      <Stack
        direction="row"
        spacing="8px"
        alignItems="center"
        onClick={() => {
          setIsOpen((isOpen) => !isOpen);
        }}
        sx={{ py: "8px", cursor: "pointer" }}
      >
        <Box
          sx={{
            ...(!isOpen && {
              transform: "rotate(180deg)",
              transformOrigin: "center",
            }),
            transition: "transform 0.2s",
          }}
        >
          <UpArrowSvg />
        </Box>
        <Typography sx={{ fontSize: "14px", opacity: 0.6 }}>{title}</Typography>
      </Stack>

      {isOpen && (
        <Stack sx={{ pl: "15px" }} spacing={"4px"}>
          {taskList ? (
            taskList.map(
              (
                {
                  taskId,
                  taskKey,
                  isOpen,
                  isDone,
                  isLocked,
                  hasMissingValue,
                  linkedThoughtRecordKey,
                  setNumber,
                },
                index
              ) => (
                <Box
                  key={taskKey}
                  ref={(el) => (taskRefs.current[index] = el as HTMLDivElement)}
                >
                  <TaskItem
                    taskKey={taskKey}
                    isOpen={isOpen}
                    isDone={isDone}
                    isLocked={isLocked}
                    hasMissingValue={hasMissingValue}
                    isPreviousTaskUndone={
                      freeTaskList.includes(taskId) &&
                      taskId !== onboardingTaskId &&
                      taskList.find(
                        (element) => element.taskId === onboardingTaskId
                      ) &&
                      !taskList.find(
                        (element) => element.taskId === onboardingTaskId
                      )?.isDone
                    }
                    linkedThoughtRecordKey={linkedThoughtRecordKey}
                    setNumber={setNumber}
                    hasConnectionLine={
                      index < taskList.length - 1 &&
                      ((getAutomaticThoughtId(taskId) &&
                        getAutomaticThoughtId(taskId) ===
                          getAutomaticThoughtId(taskList[index + 1].taskId)) ||
                        setNumber === taskList[index + 1].setNumber ||
                        //다관생에서 생각함정 잇기 위함
                        (taskId.startsWith("4-2") &&
                          taskList[index + 1].taskId.startsWith("4-4-")))
                    }
                    connectionTop={taskRefs.current[index]?.offsetHeight / 2}
                    connectionHeight={
                      index < taskList.length - 1
                        ? taskRefs.current[index + 1]?.offsetTop -
                          taskRefs.current[index]?.offsetTop +
                          (taskRefs.current[index + 1]?.offsetHeight -
                            taskRefs.current[index]?.offsetHeight) /
                            2
                        : 0
                    }
                    isFirstOfSet={
                      index === 0 ||
                      ((!getAutomaticThoughtId(taskId) ||
                        getAutomaticThoughtId(taskId) !==
                          getAutomaticThoughtId(taskList[index - 1].taskId)) &&
                        setNumber !== taskList[index - 1].setNumber &&
                        !(
                          taskId.startsWith("4-4") &&
                          taskList[index - 1].taskId.startsWith("4-2-A")
                        ))
                    }
                    isLastOfSet={
                      index > taskList.length - 3 ||
                      ((!getAutomaticThoughtId(taskId) ||
                        getAutomaticThoughtId(taskId) !==
                          getAutomaticThoughtId(taskList[index + 2].taskId)) &&
                        setNumber !== taskList[index + 2].setNumber &&
                        !(
                          taskId.startsWith("3-3") &&
                          taskList[index + 2].taskId.startsWith("4-4")
                        ) &&
                        //근거 부족 다루기 - 생각 그물과 거리두기 잇기 위함
                        !(
                          getAutomaticThoughtId(taskList[index + 1].taskId) &&
                          getAutomaticThoughtId(taskList[index + 2].taskId) &&
                          getAutomaticThoughtId(taskList[index + 1].taskId) ===
                            getAutomaticThoughtId(taskList[index + 2].taskId)
                        ))
                    }
                  />
                </Box>
              )
            )
          ) : thoughtRecordList ? (
            thoughtRecordList.map(
              ({ thoughtRecordKey, title, date, isOpen, isDone }, index) => (
                <ToolItem
                  key={`thoughtRecord_${index}`}
                  thoughtRecordKey={thoughtRecordKey}
                  title={title}
                  date={date}
                  isOpen={isOpen}
                  isDone={isDone}
                />
              )
            )
          ) : meditationList ? (
            meditationList.map(
              (
                {
                  meditationRecordKey,
                  title,
                  date,
                  durationMinutes,
                  isOpen,
                  isDone,
                  isOldVersion,
                },
                index
              ) => (
                <ToolItem
                  key={`meditationRecord_${index}`}
                  meditationRecordKey={meditationRecordKey}
                  title={title}
                  date={date}
                  durationMinutes={durationMinutes}
                  isOpen={isOpen}
                  isDone={isDone}
                  isOldVersion={isOldVersion}
                />
              )
            )
          ) : (
            <></>
          )}
        </Stack>
      )}
    </Box>
  );
}
