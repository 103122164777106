import { useNavigate, useParams } from "react-router-dom";
import Acquisition from "./Acquisition";
import FullPageTemplate from "./FullPageTemplate";
import onboarding1 from "../../static/image/onboarding/onboarding1.png";
import onboarding2 from "../../static/image/onboarding/onboarding2.png";
import onboarding3 from "../../static/image/onboarding/onboarding3.png";

export default function Onboarding() {
  let navigate = useNavigate();

  const { id } = useParams();
  const numId = parseInt(id || "1");

  const dataList = [
    {
      title:
        "환영합니다. 디스턴싱은 배우고, 연습하고, 실천하는 프로그램이에요.",
      image: onboarding1,
    },
    {
      title: "전문 코치가 나에게 맞는 활동으로 프로그램을 진행해줄 거예요.",
      image: onboarding2,
    },
    {
      title: "무엇을 보고 디스턴싱을 시작해보려  결심하셨나요?",
      type: "acquisition",
    },
    {
      title: "좋습니다. 이제 건강하고 가치있는 삶으로의 여정을 시작해볼까요?",
      image: onboarding3,
    },
  ];
  const goNext = () => {
    navigate(numId === dataList.length ? "/" : `/onboarding/${numId + 1}`);
  };

  return dataList[numId - 1].type === "acquisition" ? (
    <Acquisition
      title={dataList[numId - 1].title}
      progress={(numId / dataList.length) * 100}
      actionAfterSubmit={goNext}
    />
  ) : (
    <FullPageTemplate
      title={dataList[numId - 1].title}
      image={dataList[numId - 1].image}
      buttonText={"다음"}
      buttonDisabled={false}
      progress={(numId / dataList.length) * 100}
      buttonClickAction={goNext}
    ></FullPageTemplate>
  );
}
