import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { SidebarMenuType } from "components/sidebar/SideBar";
import { SvgIcon } from "@mui/joy";

export default function BackToPatientListButton({
  setCurrentMenu,
}: {
  setCurrentMenu: React.Dispatch<React.SetStateAction<SidebarMenuType>>;
}) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "var(--StickyHeader-height)",
        position: "sticky",
        top: 0,
        px: "6px",
        display: "flex",
        alignSelf: "center",
        backgroundColor: "background.level2",
        borderBottom: "1px solid",
        borderColor: "divider",
        zIndex: 10,
      }}
    >
      <Typography
        startDecorator={
          <SvgIcon sx={{ width: "15px", height: "15px" }}>
            <ArrowBackIosRoundedIcon />
          </SvgIcon>
        }
        level="title-md"
        onClick={() => {
          setCurrentMenu("patient");
        }}
        sx={{ cursor: "pointer" }}
      >
        목록으로 돌아가기
      </Typography>
    </Box>
  );
}
